
import TradeModel from '@/modules/trade/TradeModel'
import { add } from 'essential/tools/math'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheTradeCost',
  setup () {
    const themeClass = inject<string>('themeClass')
    const order = inject('model') as TradeModel
    const noFee = computed(() => order.getValue().isJuan)

    const cost = computed(() => {
      if (noFee.value) return 0
      const product = order.getProduct().value
      if (product) {
        return add(product.cost, product.sxf)
      }
      return 0
    })

    return {
      themeClass,
      cost,
    }
  },
})
