/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheCreateVolumes.vue of WeTrade
*/
<template>
<div class="volume">
  <t as="p" path="placeorder_4" class="f-nm f-bold c-text">Volume</t>
  <HoldOn :content="products" :height="190">
    <Matrix #="{ item }" :list="products" :identify="v => v.choiceId" class="mt-12">
      <ChoiceButton
        :class="['t-center', { active: item.choiceId === choice.choiceId }]"
        @click="select(item)"
      >
        <p class="f-sm mb-4">{{prop('weight', item)}} {{prop('unit', item)}}</p>
        <money :v="item.cost"
          :class="['f-bold',{price: item.choiceId !== choice.choiceId}]" />
      </ChoiceButton>
    </Matrix>
    <div v-if="choice" class="f-md more mb-12 br-lg" :class="{active: !choice.isOften}"
      @click="showMore">
        <span v-if="!choice.isOften">
          <money :v="choice.cost"
            class="f-bold" /> {{prop('weight', choice)}} {{prop('unit', choice)}}
        </span>
      <t path="placeorder_5" v-else>More options of volume</t>
      <Icon name="caret-down" sm class="pull-right c-text" />
    </div>
    <p class="f-sm tip">
      <t path="placeorder_6" class="mr-4">Margin required:</t>
      <money class="c-primary f-medium" :v="prop('cost', choice)" />
      <t path="placeorder_7" class="mx-4">and value per pip:</t>
      <money class="c-primary f-medium" :v="prop('yk', choice)" #={value}>
        {{value + ' ' + prop('ykUnit', choice)}}
      </money>
    </p>
  </HoldOn>
</div>
</template>

<script lang="ts">
import ChoiceButton from '@/components/ChoiceButton.vue'
import Icon from '@/components/Icon.vue'
import { openFullscreenList } from '@/components/popup/popup'
import TheTradeProductsMore from '@/modules/trade/components/TheTradeProductsMore.vue'
import {
  useSelectProduct,
} from '@/modules/trade/trade'
import { Product } from '@/modules/trade/trade.api'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheTradeProducts',
  components: {
    ChoiceButton,
    HoldOn,
    Icon,
    Matrix,
  },
  setup () {
    const code = useRoute().params.code as string

    const {
      products,
      choice,
      update: select,
    } = useSelectProduct(code)

    const showMore = () => openFullscreenList(TheTradeProductsMore, {
      choice,
      onSelect (option: Product) {
        select(option)
      },
    })

    return {
      products,
      choice,
      select,
      showMore,
    }
  },
})
</script>

<style scoped lang="scss">
.volume {
  color: var(--color-title);
}

.price {
  color: var(--color-text);
}

.more {
  border: 1px solid transparent;
  padding: 12px 16px;
  background: var(--color-light);

  &.active {
    border-radius: 2px;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: #E6EDFE;
  }
}

.tip {
  color: var(--color-tip);
}

</style>
