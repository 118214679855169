/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheCreatePaymentMethods.vue of WeTrade
*/
<template>
<div>
  <t as="p" path="placeorder_10" class="c-text f-nm f-bold mb-16">Payment method</t>
  <div class="d-f mt-8 type" :class="{'f-bold': !useCoupon}" @click="useCash(true)">
    <div class="flex-1">
      <Radio name="channel" :value="false" v-model="useCoupon">
        <t path="placeorder_11" class="ml-8">Margin Available</t>
      </Radio>
    </div>
    <TheAsset #="{freeMargin}">
      <money class="df-middle" :v="freeMargin" />
    </TheAsset>
  </div>
  <AccountRelated use-in-real>
    <div
      class="d-f mt-16 type"
      :class="{'f-bold': useCoupon, disabled: !usableCouponCount}"
      @click="useCash(false)"
    >
      <div class="flex-1">
        <Radio name="channel" :value="true" v-model="useCoupon">
          <money :v="couponPrice" #={text}>
            <t path="placeorder_12" :args="{coupon: text}" class="ml-8">{{ value }}Coupons</t>
          </money>
        </Radio>
      </div>
      <div>{{usableCouponCount}}</div>
    </div>
  </AccountRelated>
</div>
</template>

<script lang="ts">
import Radio from '@/components/Radio.vue'
import { checkUsableCoupon } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import * as R from 'ramda'
import { computed, defineComponent, inject, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheTradePaymentMethods',
  components: { TheAsset, Radio, AccountRelated },
  props: {
    price: String,
  },
  setup () {
    const usableCouponCount = shallowRef(0)
    const order = inject('model') as TradeModel
    const useCoupon = computed(() => Boolean(order.getValue().isJuan))
    const couponPrice = shallowRef(0)

    watchEffect(() => {
      const price = order?.getProduct().value?.cost
      couponPrice.value = Number(price)
      checkUsableCoupon().then(resp => {
        usableCouponCount.value = R.length(R.filter(R.propEq('price', Number(price)), resp))
        if (!usableCouponCount.value) order.useCoupon(false)
      })
    })

    return {
      usableCouponCount,
      useCoupon,
      couponPrice,
      useCash (state: boolean) {
        order.useCoupon(!state)
      },
    }
  },
})
</script>

<style scoped>
.type.active {
  font-weight: bold;
}

.type.disabled {
  color: var(--color-text);
}
</style>
