/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheCreateTargetPrice.vue of WeTrade
*/
<template>
<div>
  <t as="p" path="placeorder_8" class="c-text f-nm f-bold mt-12 ">Entry Price</t>
  <input v-model="model.price" class="input mt-8 br-lg" type="number" />
  <div class="df-middle mt-12">
    <t path="placeorder_9" :args="{offset: model.offset}" as="p" class="flex-1 c-tip f-sm">
      The maximum difference between the transition price and the set price is <span
      class="c-primary f-medium">{{model.offset}}</span> pips.
    </t>
    <!--<button class="edit">edit</button>-->
  </div>
</div>
</template>

<script lang="ts">
import TradeModel from '@/modules/trade/TradeModel'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheTradeTargetPrice',
  setup () {
    const order = inject('model') as TradeModel

    return {
      model: order.getValue(),
    }
  },
})
</script>

<style scoped>
.input {
  height: 40px;
  width: 100%;
  border: 1px solid #D5DEE8;
  background: var(--color-background);
}

.edit {
  margin-left: 8px;
  border: 1px solid var(--color-tip);
  border-radius: 10px;
  background: none;
  font-size: 12px;
  font-weight: 500;
}
</style>
