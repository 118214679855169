
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import {
  getProductsByCode,
} from '@/modules/trade/trade'
import { Product } from '@/modules/trade/trade.api'
import Holder from '@/provider/Holder.vue'
import { YesOrNo } from '@/types'
import List from '@/components/AsyncNullableList.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheTradeProductsMore',
  components: { Holder, Scroller, List, Button, PageHeader, FullScreen },
  props: {
    choice: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'select'],
  setup (props, ctx) {
    const code = useRoute().params.code as string
    const products = shallowRef()
    getProductsByCode(code, YesOrNo.NO).then((res) => {
      products.value = res
    })
    const select = shallowRef<Product>(props.choice as Product)

    return {
      products,
      select,
      update (product: Product) {
        select.value = product
      },
      emit () {
        ctx.emit('select', select.value)
        ctx.emit('close')
      },
    }
  },
})
