/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheCreateCost.vue of WeTrade
*/
<template>
  <div>
    <t path="placeorder_17" class="c-text f-nm f-bold f-md">Total Cost</t>
    <money class="f-xl f-bold pull-right" :class="`c-${themeClass}`" :v="cost" />
  </div>
</template>

<script lang="ts">
import TradeModel from '@/modules/trade/TradeModel'
import { add } from 'essential/tools/math'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheTradeCost',
  setup () {
    const themeClass = inject<string>('themeClass')
    const order = inject('model') as TradeModel
    const noFee = computed(() => order.getValue().isJuan)

    const cost = computed(() => {
      if (noFee.value) return 0
      const product = order.getProduct().value
      if (product) {
        return add(product.cost, product.sxf)
      }
      return 0
    })

    return {
      themeClass,
      cost,
    }
  },
})
</script>
