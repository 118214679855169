
// import TheTradeLimitSelector from '@/modules/trade/components/TheTradeLimitSelector.vue'
import TradeModel from '@/modules/trade/TradeModel'
import { computed, defineComponent, inject } from 'vue'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'

export default defineComponent({
  name: 'TheTradeLimits',
  components: {
    // TheTradeLimitSelector,
  },
  setup () {
    const order = inject('model') as TradeModel
    const noFee = computed(() => order.getValue().isJuan)

    return {
      noFee,
      model: order.getValue(),
      product: order.getProduct(),
      islamFlag: Boolean(Number(localGet(keymap.user.islamFlag))) || false,
      setProfitLimit (value: number) {
        order.setTopLimit(value)
      },
      setLossLimit (value: number) {
        order.setBottomLimit(value)
      },
    }
  },
})
