/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheCreateLimits.vue of WeTrade
*/
<template>
  <div class="d-f f-md">
    <div class="flex-1 c-title">
      <t path="placeorder_13">Commission</t>
      <!-- <t as="p" path="placeorder_15">Take Profit</t> -->
      <money class="c-primary ml-8" :v="noFee ? 0: prop('sxf', product)" />
    </div>
    <!--<div class="flex-1 f-medium">-->
    <!--  <p>-->
    <!--    <TheTradeLimitSelector :value="model.stopProfit" :type="1" :product="product" @change="setProfitLimit" />-->
    <!--  </p>-->
    <!--</div>-->
    <div class="flex-1">
      <t path="placeorder_14" class="c-title">Swap</t>
      <!-- <t as="p" path="placeorder_16">Stop Loss</t> -->
      <money class="ml-8" :v="(noFee || islamFlag) ? 0 : prop('deferred', product)" />/day
    </div>
    <!--<div class="flex-1 c-text f-medium">-->
    <!--  <p>-->
    <!--    <TheTradeLimitSelector :value="model.stopLoss" :type="0" :product="product" @change="setLossLimit" />-->
    <!--  </p>-->
    <!--</div>-->
  </div>
</template>

<script lang="ts">
// import TheTradeLimitSelector from '@/modules/trade/components/TheTradeLimitSelector.vue'
import TradeModel from '@/modules/trade/TradeModel'
import { computed, defineComponent, inject } from 'vue'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'

export default defineComponent({
  name: 'TheTradeLimits',
  components: {
    // TheTradeLimitSelector,
  },
  setup () {
    const order = inject('model') as TradeModel
    const noFee = computed(() => order.getValue().isJuan)

    return {
      noFee,
      model: order.getValue(),
      product: order.getProduct(),
      islamFlag: Boolean(Number(localGet(keymap.user.islamFlag))) || false,
      setProfitLimit (value: number) {
        order.setTopLimit(value)
      },
      setLossLimit (value: number) {
        order.setBottomLimit(value)
      },
    }
  },
})
</script>

<style scoped>
p:nth-child(2) {
  margin-top: 16px;
}

.dropdown {
  font-size: 13px;
  padding: 2px 4px 2px 8px;
  border-radius: 2px;
  background: var(--color-light);
}
</style>
