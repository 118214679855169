/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/5
* @description
*   TheCreateMoreVolumes.vue of WeTrade
*/
<template>
  <FullScreen class="df-col">
    <PageHeader class="px-16">
      <template #title><t path="placeorder_20">Volume</t></template>
    </PageHeader>
    <Scroller css-only class="px-16 flex-1 pb-8">
      <Holder :content="products" :skeleton-repeat="4">
        <div class="header df-middle c-title">
          <t as="div" path="placeorder_21" class="flex-1">Volume</t>
          <t as="div" path="placeorder_22" class="flex-1 t-center">Margin</t>
          <t as="div" path="placeorder_23" class="flex-1 t-right">Profit per pip</t>
        </div>
        <List as="ol" :list-data="products" #="{ item }" :identify="v => v.choiceId">
          <li
            class="option df-middle br-lg"
            :class="{active: item.choiceId === select.choiceId}"
            @click="update(item)"
          >
            <span class="flex-1">{{item.weight}} {{item.unit}}</span>
            <money as="div" class="flex-1 t-center" :v="item.cost" />
            <money as="div" class="flex-1 t-right" :v="item.yk" />
          </li>
        </List>
      </Holder>
    </Scroller>
    <div class="controls d-f f-bold">
      <Button class="flex-1" @click="$emit('close')">
        <t path="placeorder_24" #="{td}" custom>{{ td || 'Cancel' }}</t>
      </Button>
      <Button class="flex-1 primary" @click="emit">
        <t path="placeorder_25" #="{td}" custom>{{ td || 'OK' }}</t>
      </Button>
    </div>
  </FullScreen>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import {
  getProductsByCode,
} from '@/modules/trade/trade'
import { Product } from '@/modules/trade/trade.api'
import Holder from '@/provider/Holder.vue'
import { YesOrNo } from '@/types'
import List from '@/components/AsyncNullableList.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheTradeProductsMore',
  components: { Holder, Scroller, List, Button, PageHeader, FullScreen },
  props: {
    choice: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'select'],
  setup (props, ctx) {
    const code = useRoute().params.code as string
    const products = shallowRef()
    getProductsByCode(code, YesOrNo.NO).then((res) => {
      products.value = res
    })
    const select = shallowRef<Product>(props.choice as Product)

    return {
      products,
      select,
      update (product: Product) {
        select.value = product
      },
      emit () {
        ctx.emit('select', select.value)
        ctx.emit('close')
      },
    }
  },
})
</script>

<style scoped lang="scss">
.header {
  height: 40px;
  padding: 0 24px;
  font-size: 12px;
}

.option {
  height: 40px;
  margin-top: 8px;
  padding: 0 24px;
  border: 1px solid transparent;
  background: var(--color-light);

  &.active {
    border-color: var(--color-primary);
    background: #E6EDFE;
  }
}
</style>
