
import TradeModel from '@/modules/trade/TradeModel'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheTradeTargetPrice',
  setup () {
    const order = inject('model') as TradeModel

    return {
      model: order.getValue(),
    }
  },
})
