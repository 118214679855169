
import ChoiceButton from '@/components/ChoiceButton.vue'
import Icon from '@/components/Icon.vue'
import { openFullscreenList } from '@/components/popup/popup'
import TheTradeProductsMore from '@/modules/trade/components/TheTradeProductsMore.vue'
import {
  useSelectProduct,
} from '@/modules/trade/trade'
import { Product } from '@/modules/trade/trade.api'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheTradeProducts',
  components: {
    ChoiceButton,
    HoldOn,
    Icon,
    Matrix,
  },
  setup () {
    const code = useRoute().params.code as string

    const {
      products,
      choice,
      update: select,
    } = useSelectProduct(code)

    const showMore = () => openFullscreenList(TheTradeProductsMore, {
      choice,
      onSelect (option: Product) {
        select(option)
      },
    })

    return {
      products,
      choice,
      select,
      showMore,
    }
  },
})
