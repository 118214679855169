
import Radio from '@/components/Radio.vue'
import { checkUsableCoupon } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import * as R from 'ramda'
import { computed, defineComponent, inject, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheTradePaymentMethods',
  components: { TheAsset, Radio, AccountRelated },
  props: {
    price: String,
  },
  setup () {
    const usableCouponCount = shallowRef(0)
    const order = inject('model') as TradeModel
    const useCoupon = computed(() => Boolean(order.getValue().isJuan))
    const couponPrice = shallowRef(0)

    watchEffect(() => {
      const price = order?.getProduct().value?.cost
      couponPrice.value = Number(price)
      checkUsableCoupon().then(resp => {
        usableCouponCount.value = R.length(R.filter(R.propEq('price', Number(price)), resp))
        if (!usableCouponCount.value) order.useCoupon(false)
      })
    })

    return {
      usableCouponCount,
      useCoupon,
      couponPrice,
      useCash (state: boolean) {
        order.useCoupon(!state)
      },
    }
  },
})
